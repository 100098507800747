import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import Carbon from '../images/Carbon2.jpg'
import './downloads.scss'
import  { AiOutlineFilePdf } from 'react-icons/ai'
export default function Downloads() {
    return (
        <Layout>
                <div className="page-header">
                    <h4>Tomcat <span className="blue"> Downloads</span></h4>
                </div>
            <PaddedLayout>
                <div className="product-wrapper">
                    <div className="heading"><h4>Carbon</h4></div>
                        <div>
                            <ul>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Brochure</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Technical Specs</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Parts</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Operator Manual</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Warranty</a></li>
                            </ul>
                        </div>
                        <div>
                    </div>
                </div>
                <div className="product-wrapper">
                    <div className="heading"><h4>Micro Mini</h4></div>
                        <div>
                            <ul>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Brochure</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Technical Specs</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Parts</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Operator Manual</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Warranty</a></li>
                            </ul>
                        </div>
                        <div>
                    </div>
                </div>
                <div className="product-wrapper">
                    <div className="heading"><h4>Sport</h4></div>
                        <div>
                            <ul>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Brochure</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Technical Specs</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Parts</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Operator Manual</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Warranty</a></li>
                            </ul>
                        </div>
                        <div>
                    </div>
                </div>
                <div className="product-wrapper">
                    <div className="heading"><h4>Carbon</h4></div>
                        <div>
                            <ul>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Brochure</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Technical Specs</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Parts</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Operator Manual</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Warranty</a></li>
                            </ul>
                        </div>
                        <div>
                    </div>
                </div>
                <div className="product-wrapper">
                    <div className="heading"><h4>Hero</h4></div>
                        <div>
                            <ul>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Brochure</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Technical Specs</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Parts</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Operator Manual</a></li>
                                <li><a href="javascript:void(0)"><AiOutlineFilePdf /> Warranty</a></li>
                            </ul>
                        </div>
                        <div>
                    </div>
                </div>
            </PaddedLayout>
        </Layout>
    )
}
